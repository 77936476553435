.seemorehover:hover{
    cursor: pointer;
    outline: none;
}
.blank_row
{
    height: 10px !important; /* overwrites any other rules */
    background-color: #FFFFFF;
}


/* .lightTable tr th {
    background: #5E75C3 !important;
}

.lightTable tr:nth-child(4n+1) th, tr:nth-child(4n+2) th {
    background: #FFF !important;
} */
