.status table {
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
  }
  
  .status th,.status td {
    padding: 1em;
   
    border-bottom: 2px solid white; 
  }
  